<template>
  <CRow class="mb-5">
    <CCol lg="4" xl="3">
      <TAside class="card">
        <CListGroup>
          <CListGroupItem>
            <CRow>
              <CCol col="12" lg="12" xl="6">
                <TMessage content="From date" bold class="mb-2" />
                <TInputDateTime
                  :value.sync="dateRange.start"
                  timeline="start"
                  placeholder="Start date"
                  class="mb-3"
                  :key="startDateKey"
                />
              </CCol>
              <CCol col="12" lg="12" xl="6">
                <TMessage content="To date" bold class="mb-2" />
                <TInputDateTime
                  :value.sync="dateRange.end"
                  timeline="end"
                  placeholder="End date"
                  class="mb-3"
                  :key="endDateKey"
                />
              </CCol>
              <CCol col="12" class="mb-3">
                <SSelectStatus
                  :value.sync="status"
                  label="Status"
                  :options="[
                    {
                      id: '',
                      name: this.$t('All'),
                    },
                    {
                      id: 'not_payment_due_date_yet_with_expected',
                      name: this.$t('Not payment due date yet'),
                    },
                    {
                      id: 'overdue_payment_with_expected',
                      name: this.$t('Payment overdue'),
                    },
                    {
                      id: 'payment_due_today_with_expected',
                      name: this.$t('Payment due date is today'),
                    },
                    {
                      id: 'expected_payment_due_date_empty',
                      name: this.$t('No payment due date'),
                    },
                    {
                      id: 'purchase_cost_outstanding',
                      name: this.$t('Payment not completed'),
                    },
                  ]"
                />
              </CCol>
              <CCol col="12" class="mb-3">
                <TInputText
                  label="Order Id"
                  placeholder="Order Id"
                  :value.sync="filter['id']"
                  :inputOptions="{ lazy: true }"
                  @input="filter['id'] = $event"
                />
              </CCol>
              <CCol col="12" class="mb-3">
                <SSelectCustomer
                  label="Customer"
                  :value.sync="filter.customer_id"
                  noCustomLabel
                  prependAll
                />
              </CCol>
              <CCol col="12" class="mb-3">
                <SSelectContract
                  label="Contract"
                  :value.sync="filter.contract_id"
                  noCustomLabel
                  prependAll
                />
              </CCol>
            </CRow>
            <CRow>
              <CCol col="12" class="d-flex mt-2">
                <TButton
                  class="w-100"
                  content="Filter"
                  @click="onFilter"
                  :disabled="loading || !dateRange.end"
                />
                <TButtonClear
                  @click="clearFilter"
                  variant="outline"
                  class="ml-1"
                  size=""
                />
              </CCol>
            </CRow>
          </CListGroupItem>
          <CListGroupItem
            v-for="(object, index) in list"
            :key="`${index}`"
            class=""
            :color="
              selected.customer_id_selected == object.customer_id
                ? 'primary'
                : 'secondary'
            "
            :to="
              !loading
                ? _.getAppendRouteQuery($route, {
                    customer_id_selected: object.customer_id,
                  })
                : ''
            "
          >
            <div class="d-flex">
              <TMessage
                :content="
                  object.customer ? object.customer.name : object.customer_id
                "
                color="primary"
                noTranslate
              />
              <TMessageMoney
                :amount="object.purchase_cost_outstanding"
                class="ml-auto"
                currency="JPY"
              />
            </div>
          </CListGroupItem>
          <CListGroupItem v-if="loading">
            <TSpinner :loading="loading" />
          </CListGroupItem>
          <CListGroupItem v-if="!list.length && !loading">
            <TMessageNotFound />
          </CListGroupItem>
          <SButtonLoadmore
            v-if="!loading && list.length"
            store="order.statistics_payment_due_date_users"
            class="mt-2 mb-3"
          />
        </CListGroup>
      </TAside>
    </CCol>
    <CCol lg="8" xl="9" v-if="queryRoute.customer_id_selected">
      <div class="card p-3">
        <CRow>
          <CCol md="6">
            <TMessage content="Customer" bold color="primary" />
            <TMessage
              v-if="!lodash.isEmpty(detail)"
              :content="
                detail.customer ? detail.customer.name : detail.customer_id
              "
              noTranslate
              bold
              class="mb-2 h4"
            />
          </CCol>
          <CCol md="6">
            <TMessage
              content="Purchase cost outstanding"
              bold
              color="primary"
            />
            <TMessageMoney
              :amount="detail.purchase_cost_outstanding"
              class="h4"
              currency="JPY"
            />
          </CCol>
        </CRow>
      </div>
      <TTableAdvance
        :items="tableList"
        :fields="fields"
        store="order.payment_orders"
        @click-clear-filter="clearFilter"
        resource=""
        noFilter
        v-if="fetchFirstTime"
      >
        <template #customer="{ item }">
          <td>
            <TMessage
              :content="item.customer ? item.customer.name : item.customer_id"
              noTranslate
              class="text-nowrap"
            />
          </td>
        </template>
        <template #contract_id="{ item }">
          <td>
            <TMessage
              :content="item.contract_id"
              noTranslate
              class="text-nowrap"
            />
          </td>
        </template>
        <template #payment_due_date="{ item }">
          <td>
            <TMessageDateTime :content="item.payment_due_date" dateOnly />
          </td>
        </template>
        <template #id="{ item }">
          <td>
            <TLink
              :content="item.id"
              :to="`/sale/orders/${item.type_id}/${item.id}`"
            />
          </td>
        </template>
        <template #shipping_fee_outstanding="{ item }">
          <td>
            <TMessageMoney
              v-for="(lading_bill, index) in item.lading_bills"
              :key="'shipping-fee-to-pay-' + index"
              :amount="lading_bill.freight_charge_outstanding"
              :currency="lading_bill.currency_id"
            />
          </td>
        </template>
        <template #service_fee_outstanding="{ item }">
          <td>
            <TMessageMoney
              :amount="item.service_fee_outstanding"
              :currency="item.service_fee_currency_id"
            />
          </td>
        </template>
        <template #purchase_cost_outstanding="{ item }">
          <td>
            <TMessageMoney
              :amount="item.purchase_cost_outstanding"
              :currency="item.purchase_cost_currency_id"
            />
          </td>
        </template>
        <template #shipping_fee="{ item }">
          <td class="text-nowrap">
            <div
              v-for="(lading_bill, index) in item.lading_bills"
              :key="'shipping-fee-' + index"
              class="d-flex"
            >
              <TMessageMoney
                :amount="lading_bill.freight_charge_paid"
                :currency="lading_bill.currency_id"
                color="muted"
                noBold
              />
              <span class="mx-1">/</span>
              <TMessageMoney
                :amount="lading_bill.freight_charge"
                :currency="lading_bill.currency_id"
                color="muted"
              />
            </div>
          </td>
        </template>
        <template #service_fee="{ item }">
          <td>
            <div class="d-flex">
              <TMessageMoney
                :amount="item.service_fee_paid"
                :currency="item.service_fee_currency_id"
                color="muted"
                noBold
              />
              <span class="mx-1">/</span>
              <TMessageMoney
                :amount="item.service_fee"
                :currency="item.service_fee_currency_id"
                color="muted"
              />
            </div>
          </td>
        </template>
        <template #purchase_cost="{ item }">
          <td>
            <div class="d-flex">
              <TMessageMoney
                :amount="item.purchase_cost_paid"
                :currency="item.purchase_cost_currency_id"
                color="muted"
                noBold
              />
              <span class="mx-1">/</span>
              <TMessageMoney
                :amount="item.purchase_cost"
                :currency="item.purchase_cost_currency_id"
                color="muted"
              />
            </div>
          </td>
        </template>
      </TTableAdvance>
    </CCol>
  </CRow>
</template>

<script>
import paymentDueDateMixin from "../../mixins/paymentDueDateMixin";
export default {
  mixins: [paymentDueDateMixin],
  data() {
    return {
      fields: [
        {
          key: "id",
          label: "Order Id",
          _style: "width: 130px; min-width: 130px",
        },
        {
          key: "contract_id",
          label: "Contract",
          _style: "width: 170px; min-width: 170px",
          _classes: "text-nowrap",
        },
        {
          key: "payment_due_date",
          label: "Payment due date",
          _style: "width: 150px; min-width: 150px",
          _classes: "text-nowrap",
        },
        { key: "purchase_cost", label: "Paid/Cost of goods" },
        {
          key: "purchase_cost_outstanding",
          label: "Cost of goods outstanding",
          sorter: true,
        },
        { key: "service_fee", label: "Paid/Service fee" },
        {
          key: "service_fee_outstanding",
          label: "Service fee outstanding",
          sorter: true,
        },
      ],
      filterQueryPick: ["customer_id", "contract_id"],
      objectStore: "order.statistics_payment_due_date_users",
      tableStore: "order.payment_orders",
      mainKey: "customer_id",
      keySelected: "customer_id_selected",
      filterBetweenKey: "payment_due_date",
    };
  },
  watch: {
    "queryRoute.customer_id_selected": {
      immediate: true,
      deep: true,
      handler(newId, oldId) {
        if (oldId != newId) {
          this.fetchTable();
          const objSelected = this.list.find((x) => x.customer_id == newId);
          if (objSelected)
            this.$store.commit(
              "order.statistics_payment_due_date_users.select",
              newId
            );
        }
      },
    },
  },
  methods: {
    appendRoute() {
      this.lodash.appendRouteQuery(this.$router, {
        query: this.lodash.pickBy({
          ...this.$route.query,
          customer_id: this.filter?.customer_id || "",
          contract_id: this.filter?.contract_id || "",
          start: this.dateRange?.start,
          end: this.dateRange?.end,
          status: this.status,
        }),
      });
    },
  },
};
</script>
