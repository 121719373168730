export default {
  data() {
    return {
      status: "",
      filter: {},
      dateRange: {
        start: null,
        end: null,
      },
      fetchFirstTime: false,
      startDateKey: "",
      endDateKey: "",
    }
  },
  mounted() {
    this.lodash.appendRouteQuery(this.$router, {
      query: this.lodash.omit(this.$route.query, this.keySelected),
    });
  },
  computed: {
    tableList() {
      return this.$store.getters[this.tableStore + ".list"]
    },
    list() {
      return this.$store.getters[this.objectStore + ".list"]
    },
    detail() {
      return this.$store.getters[this.objectStore + ".detail"]
    },
    loading() {
      return this.$store.getters[this.objectStore + ".loading"]
    },

    selected() {
      return {
        [this.keySelected]: this.queryRoute[this.keySelected],
      };
    },
    queryRoute() {
      return this.$route.query || {};
    },
  },
  watch: {
    dateRange: {
      immediate: true,
      deep: true,
      handler(range) {
        if (range.start > 0 && range.end > 0 && range.start > range.end) {
          this.dateRange = {
            start: this.lodash.toTimeStamp(range.end * 1000, "start"),
            end: this.lodash.toTimeStamp(range.start * 1000, "end"),
          };
          this.startDateKey = this.lodash.resetKey("startDateKey");
          this.endDateKey = this.lodash.resetKey("endDateKey");
        }
      },
    },
    queryRoute: {
      immediate: true,
      deep: true,
      handler(query) {
        if (!this.lodash.isEmpty(query)) {
          this.filter = this.lodash.pick(query, this.filterQueryPick);
          this.dateRange = {
            start: +query.start,
            end: +query.end,
          };
          this.status = query.status || "";
        }
      },
    },
  },
  methods: {
    fetchTable() {
      const status = {
        [`filter[${this.status}]`]: this.status ? 1 : 0,
      };
      const dateRange = {
        [`filter_between[${this.filterBetweenKey}]`]: `${this.dateRange.start || "infinity"},${this.dateRange.end || "infinity"
        }`,
      };
      const filterList = this.lodash.mapKeys(
        this.filter,
        function (value, key) {
          return `filter[${key}]`;
        }
      );
      const query = this.lodash.pickBy({
        ...filterList,
        ...status,
        ...dateRange,
        [`filter[${this.mainKey}]`]: this.queryRoute[this.keySelected],
      });
      this.$store
        .dispatch(this.tableStore + ".apply-query", query)
        .then(() => {
          this.fetchFirstTime = true;
        });
    },
    clearFilter() {
      this.filter = {};
      this.status = "";
      this.dateRange = {
        start: null,
        end: null,
      };
    },
    onFilter() {
      this.appendRoute();
      this.fetchList();
    },
    fetchList() {
      const status = {
        [`filter[${this.status}]`]: this.status ? 1 : 0,
      };
      const filterList = this.lodash.mapKeys(
        this.filter,
        function (value, key) {
          return `filter[${key}]`;
        }
      );
      const dateRange = {
        [`filter_between[${this.filterBetweenKey}]`]: `${this.dateRange.start || "infinity"},${this.dateRange.end || "infinity"
        }`,
      };
      const query = this.lodash.pickBy({
        ...filterList,
        ...status,
        ...dateRange,
      });
      this.$store
        .dispatch(this.objectStore + ".apply-query", query)
        .then(() => {
          const objSelected = this.list.find(
            (x) => x[this.mainKey] == this.detail[this.mainKey]
          );
          if (objSelected) {
            this.fetchTable();
            this.$store.commit(
              this.objectStore + ".detail.merge",
              objSelected
            );
          }
        })
        .catch(() => {
          this.$store.commit(this.objectStore + ".purge");
        });
    },
  },
  destroyed() {
    this.$store.commit(this.objectStore + ".purge");
  },
}
